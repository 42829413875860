import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import styled, { ThemeProvider } from 'styled-components';
import { cwTheme } from '../theme/theme.js';
import axios from 'axios';
import * as qs from 'query-string';

//Thanks to Sean Davis for this implementation https://cobwwweb.com/how-to-use-netlify-forms-with-gatsby

const ServiceHero = styled.section`
	background-color: rgba(255, 255, 255, 1);
	color: rgba(61, 61, 61, 1);
	text-align: center;
`;

const PreHeader = styled.h5`color: ${cwTheme.primaryBlue};`;

const Container = styled.div`
	max-width: 75rem;
	margin: 0 auto;
	box-sizing: content-box;
	padding: 0 .5rem;
`;

const Header = styled.h1`
	display: block;
	font-weight: 800;
	max-width: 500px;
	margin: 0 auto;
	margin-bottom: .618rem;
	font-family: ${cwTheme.serif};
`;

const SubHeader = styled.span`
	display: block;
	max-width: 500px;
	margin: 0 auto;
`;

const FormSection = styled.section`
	background-color: rgba(255, 255, 255, 1);
	color: rgba(61, 61, 61, 1);
	text-align: center;
	padding: 2rem 0 4rem 0;
`;

const SubmitButton = styled.button`
	padding: .5rem 2rem;
	vertical-align: middle;
	transition: .3s;
	display: block;
	border: none;
	border-radius: 2px;
	cursor: pointer;
	text-decoration: none;
	transition: .3s;
	font-size: 1rem;
	height: 43px;
	margin-top: 1em;
	background-color: ${(props) => props.color};
	color: ${cwTheme.white};
	&:hover,
	&:focus {
		background-color: ${(props) => props.hoverColor};
	}
`;

const ContactForm = styled.form`
	max-width: 400px;
	width: 100%;
	margin: 0 auto;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	border: 1px solid #e4e4e4;
	border-radius: ${cwTheme.borderRadius};
	padding: 1rem;
	display: flex;
	flex-direction: column;
	text-align: left;
	box-sizing: border-box;

	label {
		font-weight: 500;
		color: ${cwTheme.black};
		display: block;
		text-size: .8em;
	}

	input,
	textarea {
		box-sizing: border-box;

		font-size: 1rem;
		vertical-align: middle;
		margin-top: 0;
		margin-bottom: 1rem;
		border: 2px solid rgba(61, 61, 61, .2);
		border-radius: 2px;

		color: rgba(61, 61, 61, 1);
		transition: .3s;
		display: block;
		width: 100%;
		font-family: ${cwTheme.sansSerif};
	}

	input {
		height: 35px;
		padding: .5rem;
	}

	textarea {
		height: 200px;
		resize: none;
		padding: .5rem;
	}
	input:focus,
	textarea:focus {
		border: 2px solid rgba(55, 197, 235, 1);
		outline: none;
	}
`;

const FormResponse = styled.span`
	font-size: 1.25em;
	color: ${cwTheme.secondaryBlue};
	text-align: center;
	line-height: 1.1em;
	padding: 1rem;
`;

class ContactFormPage extends React.Component {
	constructor(props) {
		super(props);
		this.domRef = React.createRef();
		this.state = { feedbackMsg: null, emptyError: false };
	}

	toggleState() {
		this.setState({
			emptyError: this.state.emptyError ? false : true
		});
	}

	handleSubmit(event) {
		var isEmpty = false;
		// Do not submit form via HTTP, since we're doing that via XHR request.
		event.preventDefault();
		// Loop through this component's refs (the fields) and add them to the
		// formData object. What we're left with is an object of key-value pairs
		// that represent the form data we want to send to Netlify.

		const formData = {};
		Object.keys(this.refs).map((key) => {
			formData[key] = this.refs[key].value;
			if (this.refs[key].value == '') {
				isEmpty = true;
			}
		});

		if (isEmpty) {
			this.setState({
				feedbackMsg: 'Please fill out all fields before submitting.',
				emptyError: true
			});
		} else {
			this.setState({
				emptyError: false
			});
			// Set options for axios. The URL we're submitting to
			// (this.props.location.pathname) is the current page.
			const axiosOptions = {
				url: this.props.location.pathname,
				method: 'post',
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				data: qs.stringify(formData)
			};

			// Submit to Netlify. Upon success, set the feedback message and clear all
			// the fields within the form. Upon failure, keep the fields as they are,
			// but set the feedback message to show the error state.
			axios(axiosOptions)
				.then((response) => {
					this.setState({
						feedbackMsg: 'Thanks! We will get back to you ASAP!'
					});
					this.domRef.current.reset();
				})
				.catch((err) =>
					this.setState({
						feedbackMsg: 'Form could not be submitted.'
					})
				);
		}
	}

	render() {
		return (
			<ThemeProvider theme={{ mode: 'light' }}>
				<Layout>
					<SEO title="Contact" />
					<ServiceHero>
						<Container>
							<PreHeader>QUESTIONS? INQUIRIES?</PreHeader>
							<Header>Contact Us</Header>
							<SubHeader>We can't wait to hear from you &#x1F495;</SubHeader>
						</Container>
					</ServiceHero>
					<FormSection>
						<Container>
							<ContactForm
								ref={this.domRef}
								name="Contact Form"
								method="POST"
								data-netlify="true"
								onSubmit={(event) => this.handleSubmit(event)}
							>
								{this.state.feedbackMsg && <FormResponse>{this.state.feedbackMsg}</FormResponse>}
								<input ref="form-name" type="hidden" name="form-name" value="Contact Form" />
								<div>
									<label for="name">Name</label>
									<input ref="name" type="text" name="name" />
								</div>
								<div>
									<label for="company">Company</label>
									<input ref="company" type="text" name="company" />
								</div>
								<div>
									<label for="email">Email</label>
									<input ref="email" type="email" name="email" />
								</div>
								<div>
									<label for="message">Message</label>

									<textarea ref="message" name="message" />
								</div>

								<SubmitButton
									color={cwTheme.primaryBlue}
									hoverColor={cwTheme.primaryHover}
									type="submit"
								>
									Submit
								</SubmitButton>
							</ContactForm>
						</Container>
					</FormSection>
				</Layout>
			</ThemeProvider>
		);
	}
}

export default ContactFormPage;
